import * as React from 'react'

import './Gallery.css'

import SavedPainting, { Details } from '../../types/SavedPainting'

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
} from 'reactstrap'

import Wall from './Wall'

import disableScroll from 'disable-scroll'

interface Props {
  single?: boolean
  export?: boolean
  frames?: boolean
  submitted?: boolean
  setTab: (tab: number, refresh: boolean) => void
  rooms: Room[]
  userPaintings: SavedPainting[]
  loadingMore: boolean
  loadMore: () => void
  setSearchTerm: (searchTerm: string) => void
  onMount?: () => void
  searchTerm: string
}

interface Room {
  paintings: SavedPainting[]
}

interface State {}

export default class Gallery extends React.Component<Props, State> {
  state: State = {}

  isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  }

  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount()
    }
  }

  render() {
    return (
      <div className="gallery_container-1">
        <div id="gallery">
          {!this.props.single && (
            <input
              style={{
                position: 'absolute',
                top: 5,
                left: 5,
                border: 'none',
                zIndex: 99999999,
              }}
              type="text"
              placeholder="Search..."
              value={this.props.searchTerm}
              onChange={(e) => this.props.setSearchTerm(e.target.value)}
            />
          )}
          <Wall
            single={this.props.single}
            export={this.props.export}
            frames={this.props.frames}
            submitted={this.props.submitted}
            userPaintings={this.props.userPaintings}
            rooms={this.props.rooms}
            loadingMore={this.props.loadingMore}
            loadMore={this.props.loadMore}
            searchTerm={this.props.searchTerm}
          />
        </div>
        <div
          onClick={() => {
            if (this.isMobile()) {
              disableScroll.on()
            }
            this.props.setTab(
              this.props.single || this.props.frames ? 2 : 0,
              false,
            )
          }}
          className="gallery__leave"
        >
          {this.props.export
            ? null
            : this.props.single || this.props.frames
            ? '<- Back to gallery'
            : '<- Go to studio'}
        </div>
      </div>
    )
  }
}
