import * as React from 'react';

import "./Start.css";

import SavedPainting, { Details } from "../../types/SavedPainting";

import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup } from 'reactstrap';

import MondrianPortrait from "../../assets/mondrian-portrait.jpg"

import CompositionII from "../../assets/compositionii.jpeg"

import disableScroll from 'disable-scroll';

interface Props {
  setTab: (tab: number, refresh: boolean) => void
}

interface State {

};

export default class Gallery extends React.Component<Props, State> {
  state: State = {

  };

  isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }


  render() {
    return (
      <div>
        <img src={MondrianPortrait} className="start__portrait" />
        <img src={CompositionII} className="start__painting" />
        <div className="start__title">mondrian.fun</div>
        <div className="start__description">
          This site is a celebration of the artistic style of Piet Mondrian, a 20th century Dutch artist best known for his abstract works using vertical and horizontal lines and the primary colors. Here you can create your own masterpiece in the style of Mondrian and hang it in a virtual gallery alongside other people's work. Head to the studio to get started or visit the gallery for some inspiration...
        </div>
        <div onClick={() => {
          if (this.isMobile()) {
            disableScroll.on();
          } this.props.setTab(0, false)
        }} className="gallery__leave">
          {"<- Go to studio"}
        </div>
        <div onClick={() => this.props.setTab(2, false)} className="studio__leave">
          Go to gallery ->
        </div>
        <div
            style={{
              position: "absolute",
              margin: 8,
              top: 0,
              right: 0,
              fontSize: 12,
              opacity: 0.3,
              // zIndex: 1,
            }}
          >
            Site designed and built by{" "}
            <a
              style={{ color: "black", textDecoration: "underline" }}
              href="https://bengrav.es/"
              target="_blank"
            >
              Ben Graves
            </a>
          </div>
      </div>
    );
  }
}