import * as React from 'react'

import './Canvas.css'

import Painting, { Split, Block } from '../../types/Painting'

import Section from '../Section/Section'

import Explainer from '../../assets/side to side.gif'

interface Props {
  painting: Painting
  gallery?: boolean
  paint?: (painting: Painting) => void
  frame?: boolean
  paintingRef?: React.RefObject<HTMLDivElement>
  lastActionDepth?: number
  setLastActionDepth?: (depth: number) => void
}

interface State {
  hide: boolean
  i: number
  down: boolean
  interval?: any
  depth: number
  depthInterval?: any
  hover?: boolean
}

export default class Canvas extends React.Component<Props, State> {
  state: State = {
    hide: false,
    i: 0,
    down: true,
    depth: -1,
    hover: false,
  }

  componentDidUpdate() {
    if (
      !this.props.gallery &&
      !this.state.hide &&
      !this.state.interval &&
      this.props.painting.rootSection.isSplit &&
      !this.state.hide
    ) {
      setTimeout(
        () =>
          this.setState({
            interval: setInterval(() => {
              if (!this.state.hide) {
                if (this.state.down) {
                  if (this.state.i > -5) {
                    this.setState({ i: this.state.i - 1 })
                  } else {
                    this.setState({ down: false })
                  }
                } else {
                  if (this.state.i < 5) {
                    this.setState({ i: this.state.i + 1 })
                  } else {
                    this.setState({ down: true })
                  }
                }
              }
            }, 45),
          }),
        500,
      )
      setTimeout(() => {
        this.setState({ hide: true })
        clearInterval(this.state.interval)
      }, 1500)
    }
  }

  render() {
    const { painting, paint, gallery } = this.props

    return (
      <div ref={this.props.paintingRef}>
        <div
          style={{
            height: window.location.pathname.includes('export')
              ? '80vh'
              : undefined,
          }}
          className={
            gallery
              ? 'canvas__container canvas__container--gallery'
              : 'canvas__container'
          }
        >
          <div
            onMouseEnter={
              this.props.gallery
                ? () => {
                    if (this.state.hover) return

                    this.setState({
                      hover: true,
                      depth: -1,
                      depthInterval: setInterval(() => {
                        this.setState({ depth: this.state.depth + 0.25 })
                      }, 75),
                    })
                  }
                : () => {}
            }
            onMouseLeave={
              this.props.gallery
                ? () => {
                    clearInterval(this.state.depthInterval)
                    this.setState({ hover: false })
                  }
                : () => {}
            }
            className={
              gallery
                ? this.props.frame
                  ? 'canvas frame canvas--gallery canvas--' +
                    painting.canvas.shape
                  : 'canvas canvas--gallery canvas--' + painting.canvas.shape
                : 'canvas canvas--' + painting.canvas.shape
            }
          >
            {paint ? (
              <Section
                section={
                  !gallery && !this.state.hide && painting.rootSection.isSplit
                    ? {
                        ...painting.rootSection,
                        position: 50 + this.state.i,
                      }
                    : painting.rootSection
                }
                updateSection={(rootSection: Split | Block) => {
                  paint({ ...painting, rootSection })
                }}
                setLastActionDepth={this.props.setLastActionDepth}
                depthToPaintTo={
                  this.props.gallery ? this.state.depth : undefined
                }
                thisDepth={0}
                lastActionDepth={this.props.lastActionDepth}
              />
            ) : (
              <Section
                section={painting.rootSection}
                depthToPaintTo={
                  this.props.gallery && this.state.hover
                    ? this.state.depth
                    : undefined
                }
                thisDepth={0}
                lastActionDepth={this.props.lastActionDepth}
              />
            )}
            {/* {!gallery && !this.state.hide && painting.rootSection.isSplit && (
            <img
              src={Explainer}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                height: 50,
                transform:
                  painting.rootSection.direction === "horizontal"
                    ? "rotate(90deg)"
                    : "rotate(0deg)",
              }}
            />
          )} */}
          </div>
        </div>
      </div>
    )
  }
}
