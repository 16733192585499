import * as React from 'react'

import { Split, Block } from '../../types/Painting'

import SplitSection from './SplitSection'
import BlockSection from './BlockSection'

import './Section.css'

interface Props {
  section: Split | Block
  updateSection?: (section: Split | Block) => void
  depthToPaintTo?: number
  thisDepth?: number
  lastActionDepth?: number
  setLastActionDepth?: (depth: number) => void
}

interface State {}

export default class Section extends React.Component<Props, State> {
  state: State = {}

  render() {
    const {
      section,
      updateSection,
      depthToPaintTo,
      lastActionDepth,
      setLastActionDepth,
    } = this.props

    let { thisDepth } = this.props

    if (section.depth !== undefined) {
      thisDepth = section.depth
    }

    if (depthToPaintTo !== undefined && thisDepth !== undefined) {
      if (thisDepth > depthToPaintTo) {
        return (
          <BlockSection
            block={{
              color: 'white',
              id: 'empty',
              isSplit: false,
            }}
          />
        )
      }
    }

    if (this)
      return section.isSplit ? (
        updateSection ? (
          <SplitSection
            split={section}
            updateSection={updateSection}
            depthToPaintTo={depthToPaintTo}
            thisDepth={thisDepth}
            lastActionDepth={lastActionDepth}
            setLastActionDepth={setLastActionDepth}
          />
        ) : (
          <SplitSection
            split={section}
            depthToPaintTo={depthToPaintTo}
            thisDepth={thisDepth}
          />
        )
      ) : updateSection ? (
        <BlockSection
          block={section}
          updateSection={updateSection}
          depthToPaintTo={depthToPaintTo}
          thisDepth={thisDepth}
          lastActionDepth={lastActionDepth}
          setLastActionDepth={setLastActionDepth}
        />
      ) : (
        <BlockSection
          block={section}
          depthToPaintTo={depthToPaintTo}
          thisDepth={thisDepth}
        />
      )
  }
}
