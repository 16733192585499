import React from 'react'
import logo from './logo.svg'
import './App.css'

import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'

import 'bootstrap/dist/css/bootstrap.min.css'

import SavedPainting from './types/SavedPainting'

import Studio from './components/Studio/Studio'
import Gallery from './components/Gallery/Gallery'
import Start from './components/Start/Start'

import AWS from 'aws-sdk'

import GA from './utils/GoogleAnalytics'

import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

// create S3 instance
const s3 = new AWS.S3({ region: 'eu-west-2' })

interface Props {}

interface State {
  currentTab: number
  gallery: Room[]
  paintingFiles: AWS.S3.ObjectList
  userPaintings: SavedPainting[]
  loadingMore: boolean
  searchTerm: string
  toggle: boolean
}

interface Room {
  paintings: SavedPainting[]
}

export default class App extends React.Component<Props, State> {
  state: State = {
    currentTab: 0,
    gallery: [],
    paintingFiles: [],
    userPaintings: [],
    loadingMore: false,
    searchTerm: '',
    toggle: true,
  }

  setTab = (tab: number, refresh: boolean) => {
    if (refresh) {
      this.getFileNames()
    }
    this.setState({ currentTab: tab })
  }

  addUserPainting(userPainting: SavedPainting) {
    let userPaintings = this.state.userPaintings
    userPaintings = [userPainting].concat(userPaintings)
    this.setState({ userPaintings })
  }

  downloadFile = (
    fileName: string,
    room: number,
    index: number,
    displayIndex: number,
    id?: string,
  ) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET as string,
      Key: fileName,
    }
    s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err)
        return
      }

      if (data.Body !== undefined) {
        let objectData = JSON.parse(data.Body.toString()) as SavedPainting
        let gallery = this.state.gallery
        if (
          !fileName
            .toLowerCase()
            .includes(this.state.searchTerm.toLowerCase()) &&
          !fileName
            .toLowerCase()
            .includes(encodeURIComponent(this.state.searchTerm).toLowerCase())
        ) {
          return
        }
        gallery[room].paintings[index] = {
          ...objectData,
          details: {
            ...objectData.details,
            index: displayIndex,
            id,
          },
        }

        this.setState({ gallery }, () => {
          if (index === this.ROOM_SIZE - 1) {
            this.setState({ loadingMore: false })
          }
        })
      }
    })
  }

  ROOM_SIZE = 10

  getPaintings(room: number) {
    let gallery = room === 0 ? [] : this.state.gallery

    gallery.push({
      paintings: new Array(this.ROOM_SIZE).fill(null),
    })

    this.setState({ ...this.state, gallery })
    let paintingsToGet = this.state.paintingFiles
      .filter((painting) => {
        return (
          painting.Key?.toLowerCase().includes(
            this.state.searchTerm.toLowerCase(),
          ) ||
          painting.Key?.toLowerCase().includes(
            encodeURIComponent(this.state.searchTerm).toLowerCase(),
          )
        )
      })
      .slice(room * this.ROOM_SIZE, (room + 1) * this.ROOM_SIZE)

    // paintingsToGet = paintingsToGet.filter((painting: AWS.S3.Object) => this.state.userPainting === null || painting.Key !== this.state.userPainting.details.artist + this.state.userPainting.details.date + ".json")
    paintingsToGet.forEach((painting: AWS.S3.Object, index: number) => {
      if (painting.Key !== undefined) {
        this.downloadFile(
          painting.Key,
          room,
          index,
          this.state.paintingFiles.length - (room * this.ROOM_SIZE + index),
          // room * this.ROOM_SIZE + index + 1,
          painting.ETag?.split('"').join(''),
        )
      }
    })
  }

  getPainting(index: number) {
    let gallery = [
      {
        paintings: new Array(1).fill(null),
      },
    ]

    this.setState({ gallery })

    let paintingToGet =
      this.state.paintingFiles[this.state.paintingFiles.length - index]

    if (paintingToGet.Key !== undefined) {
      this.downloadFile(
        paintingToGet.Key,
        0,
        0,
        index,
        paintingToGet.ETag?.split('"').join(''),
      )
    }
  }

  listFileNames = (startAfter: string | null) => {
    let params: any = {
      Bucket: process.env.REACT_APP_S3_BUCKET as string,
      Delimiter: '/',
    }

    if (startAfter !== null) {
      params.StartAfter = startAfter
    }

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log(err)
        return
      }

      if (data.Contents !== undefined) {
        this.setState({
          paintingFiles: this.state.paintingFiles
            .concat(data.Contents)
            .sort((a: AWS.S3.Object, b: AWS.S3.Object) => {
              if (a.Key !== undefined && b.Key !== undefined) {
                const aParts = a.Key.replace('.json', '').split('ts:')
                const bParts = b.Key.replace('.json', '').split('ts:')

                let aDate = parseInt(a.Key.replace('.json', '').slice(-13))
                let bDate = parseInt(b.Key.replace('.json', '').slice(-13))

                if (aParts.length > 1) {
                  aDate = parseInt(aParts[aParts.length - 1])
                }
                if (bParts.length > 1) {
                  bDate = parseInt(bParts[bParts.length - 1])
                }
                return bDate - aDate
              } else {
                return 0
              }
            }),
        })

        if (data.Contents?.length === 0) {
          if (
            window.location.pathname === '/' ||
            window.location.pathname === '/gallery' ||
            window.location.pathname === '/gallery/submitted'
          ) {
            this.getPaintings(0)
          } else if (window.location.pathname.startsWith('/painting/')) {
            const id = window.location.pathname.split('/')[2]
            if (id.match(/^-?\d+$/)) {
              this.getPainting(parseInt(id))
            } else {
              const index = this.state.paintingFiles.findIndex(
                (paintingFile) => {
                  return paintingFile.ETag?.split('"').join('') === id
                },
              )

              if (index !== -1) {
                this.getPainting(this.state.paintingFiles.length - index)
              }
            }
          } else if (window.location.pathname === '/hall-of-fun') {
            const paintings = [
              '42eb5a1d7b872b3ace583b7ee1d9b40e',
              'e016cc54ab1ab97d232904ac84a44e13',
              'f33e582c900c2033609a34187e68a302',
              '5245e8a9df147c0cd672d406af1df2ed',
              'ed41d839a7e3af98978560fb69187252',
              '9a829816536efb1d6c3df6c6b81658bf',
              'f366ae1aebbe3bd46bfd767a6770195a',
              '8df5c7fc1ccd0877218a951c48b956fa',
              'a6999bbdcf980515d514edccc5507c05',
              '8b9dd035f0192ed0dd294582668ab9e4',
              '2db80406ca7c62233061a33531d912ad',
              '0b8a90fde145382cd96d0a875dbc399e',
              'cef05b8fba2426a3b26d5cd7bb940a75',
              'ec96e91bac0f1edb1bd63b7fc63b0bfc',
              'f973e0503c04cb0ba26110babf5a7ca6',
              '5a4f6131ecfeaa58c74aa6588e2d7ab7',
              'c65582fa8e37d4a2445bbbdbd1560969',
              '2c026f3a282b0e484a09048a8c588a3d',
              'eb6b47677e27b6d2c555255766ed4579',
              '2d6cf1f7e36867ddbacd3879b7608053',
              '80c43b85fc4ca1d43f3b30c5b3fc14ad',
              '1e5977810c220bb65ec3415b483947ca',
              '32259674ec4d8796ebe8e94fbee41e07',
              '4c1bee0e24d20d6ca569cf0c0549ca18',
              '4b289b227c41256fd9dca0d78375b328',
              '4649578e7be999f51ee9abfc27bc46c2',
              'f593682b997eb32bee31e64dccf1e509',
              '1ff6c99b9c95253fe8c1ef112d3b0bd8',
              '1493b905a6f074634f7bdf5eb418aa05',
              '5379f2c7247cf845f517c180e8b3cbce',

              'ac4b202e8aab4070d2086ea5ea6feb63',

              '9d4768c8434807bfbee351dff4909203',
              'defa6bcce20f60cdad7e2ec2c377331a',

              '49e978d62b3a83be2d95bedb99d195c8',
              'ecb5f1ec894768d28e5f6c434ffdd526',
              '60b4d4be370f3dac57ac044470dd340e',
              '7ee5ffe1ed1360ac7935da783437e22d',
              'b925ca08dcb04de653ef566a9e6dbf42',
              '493dbc5320ee7452b48b8b13d33954d9',
              '6eff6107d40e9728b4e65f1b4c211edc',
              '5b7273a59cfdf4fd02ee0a51aa01e5c1',
              'c5ee243d8eed4509e3544859944b380c',
              '3fad32a74374f80ff9dad25e6c995b3b',
              '886a8e24911a2163b7e03954ddc11a9d',
              '4341d0cc4e2c93fbedcf922c8a45a8a4',
              '31a9a482b9ba67942b56420aa7c8ab24',
              'b14537ad3f1342e3cfce658f0183e93c',
              '1f2fc02fa704114d57aada96d00eae9c',
              'a600f7718989d6e7fbf5de681fb07fd8',
              'fbad2480e2dc62eb7a2e380863f26506',
              '1e460086503143c138cbd2430df0cb7b',
              'ef2129aedadd34880c8174d0f4737044',
              'e07a46b212d57614373b541edad565e2',
              '346323fcd9b71970907d8ce0c6ed2433',
              '19255be85d4af9dc4e660533115acd05',
              '3fea2b1c1857ea116ede048efb95253c',
              '04450a43ea7cfb7adca2d0d01a85c596',
              'fe4e98e82ef8a3ea620e50d9eb2663dd',
              '5e478ae19a45d0f53b4a7f5276aa6bd0',
              '71237b3cf6fa58c288918aafe309a91a',
              '513982459468335020e53a2dfcd2149f',
              'c3825186bea28b0186c8c57bfc4bcaf4',
              'd202deeffa80b10b0223a46a429ee3b6',
              '03b7a9880fad16a35494dbf2a1a15d37',
              'ddb5e457136032b2aa3d191f2ea387a5',
              '3c169230b47387165deea74d441621d3',
              'dc25e41bebd97a88fd7120e051525a3d',
              '42247e1532ceee13682d24cea1f60e2e',
            ].sort(() => 0.5 - Math.random())

            let gallery = this.state.gallery
            gallery.push({
              paintings: new Array(this.ROOM_SIZE).fill(null),
            })
            this.setState({ gallery })

            paintings.forEach((id, paintingIndex) => {
              const index = this.state.paintingFiles.findIndex(
                (paintingFile) => {
                  return paintingFile.ETag?.split('"').join('') === id
                },
              )

              if (index !== -1) {
                let paintingToGet = this.state.paintingFiles[index]
                if (paintingToGet.Key !== undefined) {
                  this.downloadFile(
                    paintingToGet.Key,
                    0,
                    paintingIndex,
                    this.state.paintingFiles.length - index,
                    paintingToGet.ETag?.split('"').join(''),
                  )
                }
              }
            })
          }
        } else {
          this.listFileNames(
            data.Contents[data.Contents?.length - 1].Key || null,
          )
        }
      }
    })
  }

  getFileNames = () => {
    this.listFileNames(null)
  }

  componentDidMount = () => {
    this.getFileNames()
    setInterval(() => {
      this.setState({ toggle: !this.state.toggle })
    }, 3000)
  }

  render = () => {
    return (
      <div className="App">
        {/* {
          <button
            style={{
              backgroundColor: 'transparent',
              border: '2px solid black',
            }}
            onClick={async () => {
              const files = this.state.paintingFiles.sort(
                (a: AWS.S3.Object, b: AWS.S3.Object) => {
                  if (
                    a.LastModified !== undefined &&
                    b.LastModified !== undefined
                  ) {
                    return b.LastModified.getTime() - a.LastModified.getTime()
                  } else {
                    return 0
                  }
                },
              )

              console.log(files)
              const filesToChange = files
              for (const [i, fileToChange] of Object.entries(filesToChange)) {
                var BUCKET_NAME = process.env.REACT_APP_S3_BUCKET as string
                var OLD_KEY = fileToChange.Key
                const params = {
                  Bucket: process.env.REACT_APP_S3_BUCKET as string,
                  Key: OLD_KEY as string,
                }
                const data = await s3.getObject(params).promise()
                if (data.Body !== undefined) {
                  let objectData = JSON.parse(
                    data.Body.toString(),
                  ) as SavedPainting

                  console.log(`got ` + i, OLD_KEY)

                  let NEW_KEY = `${objectData.details.artist} ${objectData.details.title} ts:${objectData.details.date}.json`

                  if (/[^\u0000-\u00ff]/g.test(NEW_KEY)) {
                    console.log('change', NEW_KEY)
                    NEW_KEY = NEW_KEY.replace(/[^\u0000-\u00ff]/g, '')
                    console.log('change', NEW_KEY)
                    console.log({
                      Bucket: BUCKET_NAME,
                      CopySource: `${BUCKET_NAME}/${OLD_KEY}`,
                      Key: NEW_KEY,
                    })
                    console.log({
                      Bucket: BUCKET_NAME,
                      CopySource: encodeURIComponent(
                        `${BUCKET_NAME}/${OLD_KEY}`,
                      ),
                      Key: encodeURIComponent(NEW_KEY),
                    })
                  }

                  if (OLD_KEY !== NEW_KEY) {
                    // Copy the object to a new location
                    await s3
                      .copyObject({
                        Bucket: BUCKET_NAME,
                        CopySource: encodeURIComponent(
                          `${BUCKET_NAME}/${OLD_KEY}`,
                        ),
                        Key: encodeURIComponent(NEW_KEY),
                      })
                      .promise()
                      .then(() => {
                        console.log(`copied ` + i, NEW_KEY)
                        // Delete the old object
                        s3.deleteObject({
                          Bucket: BUCKET_NAME,
                          Key: OLD_KEY as string,
                        })
                          .promise()
                          .then(() => console.log(`deleted ` + i, OLD_KEY))
                      })
                      // Error handling is left up to reader
                      .catch((e) => console.error(e))
                  }
                } else {
                  break
                }
              }
            }}
          >
            Change Name
          </button>
        } */}
        <BrowserRouter>
          {GA.init() && <GA.RouteTracker />}
          <Switch>
            <Route path="/" exact>
              <AwesomeSlider
                mobileTouch={false}
                buttons={false}
                selected={this.state.currentTab}
                infinite={false}
                fillParent
              >
                <div>
                  {this.state.currentTab === 0 ? (
                    <Studio
                      active={this.state.currentTab === 0}
                      setTab={(tab: number, refresh: boolean) =>
                        this.setTab(tab, refresh)
                      }
                      setUserPainting={(userPainting: SavedPainting) =>
                        this.addUserPainting(userPainting)
                      }
                    />
                  ) : null}
                </div>
                <div>
                  <Start
                    setTab={(tab: number, refresh: boolean) =>
                      this.setTab(tab, refresh)
                    }
                  />
                </div>
                <div className="gallery-page">
                  <Gallery
                    searchTerm={this.state.searchTerm}
                    userPaintings={this.state.userPaintings}
                    rooms={this.state.gallery}
                    setTab={(tab: number, refresh: boolean) =>
                      this.setTab(tab, refresh)
                    }
                    loadingMore={this.state.loadingMore}
                    loadMore={() => {
                      this.setState({ loadingMore: true })
                      this.getPaintings(this.state.gallery.length)
                    }}
                    setSearchTerm={(searchTerm: string) =>
                      this.setState({ ...this.state, searchTerm }, () =>
                        this.getPaintings(0),
                      )
                    }
                  />
                </div>
              </AwesomeSlider>
            </Route>
            <Route path="/studio">
              <Studio
                active={this.state.currentTab === 0}
                setTab={(tab: number, refresh: boolean) => {
                  if (tab === 0) {
                    window.location.href = '/studio'
                  }
                  if (tab === 1) {
                    window.location.href = '/about'
                  }
                  if (tab === 2) {
                    window.location.href = '/gallery'
                  }
                  this.setTab(tab, refresh)
                }}
                setUserPainting={(userPainting: SavedPainting) =>
                  this.addUserPainting(userPainting)
                }
              />
            </Route>
            <Route path="/about">
              <Start
                setTab={(tab: number, refresh: boolean) => {
                  if (tab === 0) {
                    window.location.href = '/studio'
                  }
                  if (tab === 1) {
                    window.location.href = '/about'
                  }
                  if (tab === 2) {
                    window.location.href = '/gallery'
                  }
                  this.setTab(tab, refresh)
                }}
              />
            </Route>
            <Route path="/gallery" exact>
              <Gallery
                searchTerm={this.state.searchTerm}
                userPaintings={this.state.userPaintings}
                rooms={this.state.gallery}
                setTab={(tab: number, refresh: boolean) => {
                  if (tab === 0) {
                    window.location.href = '/studio'
                  }
                  if (tab === 1) {
                    window.location.href = '/about'
                  }
                  if (tab === 2) {
                    window.location.href = '/gallery'
                  }
                  this.setTab(tab, refresh)
                }}
                loadingMore={this.state.loadingMore}
                loadMore={() => {
                  this.setState({ loadingMore: true })
                  this.getPaintings(this.state.gallery.length)
                }}
                setSearchTerm={(searchTerm: string) =>
                  this.setState(
                    { ...this.state, gallery: [], searchTerm },
                    () => this.getPaintings(0),
                  )
                }
              />
            </Route>
            <Route path="/gallery/submitted">
              <Gallery
                searchTerm={this.state.searchTerm}
                submitted
                userPaintings={this.state.userPaintings}
                rooms={this.state.gallery}
                setTab={(tab: number, refresh: boolean) => {
                  if (tab === 0) {
                    window.location.href = '/studio'
                  }
                  if (tab === 2) {
                    window.location.href = '/gallery'
                  }
                  this.setTab(tab, refresh)
                }}
                loadingMore={this.state.loadingMore}
                loadMore={() => {
                  this.setState({ loadingMore: true })
                  this.getPaintings(this.state.gallery.length)
                }}
                setSearchTerm={(searchTerm: string) =>
                  this.setState({ ...this.state, searchTerm }, () =>
                    this.getPaintings(0),
                  )
                }
              />
            </Route>
            <Route path="/hall-of-fun">
              <Gallery
                searchTerm={this.state.searchTerm}
                frames
                userPaintings={this.state.userPaintings}
                rooms={this.state.gallery}
                setTab={(tab: number, refresh: boolean) => {
                  if (tab === 0) {
                    window.location.href = '/studio'
                  }
                  if (tab === 2) {
                    window.location.href = '/gallery'
                  }
                  this.setTab(tab, refresh)
                }}
                loadingMore={this.state.loadingMore}
                loadMore={() => {}}
                setSearchTerm={(searchTerm: string) =>
                  this.setState({ ...this.state, searchTerm }, () =>
                    this.getPaintings(0),
                  )
                }
              />
            </Route>
            <Route path="/painting/:id" exact>
              <div className="gallery-page">
                <Gallery
                  searchTerm={''}
                  single
                  userPaintings={this.state.userPaintings}
                  rooms={this.state.gallery}
                  setTab={(tab: number, refresh: boolean) => {
                    if (tab === 0) {
                      window.location.href = '/studio'
                    }
                    if (tab === 2) {
                      window.location.href = '/gallery'
                    }
                    this.setTab(tab, refresh)
                  }}
                  loadingMore={this.state.loadingMore}
                  loadMore={() => {}}
                  setSearchTerm={() => {}}
                />
              </div>
            </Route>
            <Route path="/painting/:id/export">
              <div className="gallery-page">
                <Gallery
                  searchTerm={''}
                  single
                  export
                  userPaintings={this.state.userPaintings}
                  rooms={this.state.gallery}
                  setTab={(tab: number, refresh: boolean) => {
                    if (tab === 0) {
                      window.location.href = '/studio'
                    }
                    if (tab === 2) {
                      window.location.href = '/gallery'
                    }
                    this.setTab(tab, refresh)
                  }}
                  loadingMore={this.state.loadingMore}
                  loadMore={() => {}}
                  setSearchTerm={() => {}}
                />
              </div>
            </Route>
          </Switch>
        </BrowserRouter>
        <img src="/frame.jpg" style={{ height: 0 }} />
      </div>
    )
  }
}
