import * as React from "react";

import SavedPainting, { Details } from "../../types/SavedPainting";

import InfiniteScroll from "react-bidirectional-infinite-scroll";

import toPx from "to-px";

import Canvas from "./../Canvas/Canvas";
import { Link } from "react-router-dom";

import { toPng } from 'html-to-image';

import LoadingGif from "../../assets/loading.gif";

import TwitterLogo from "../../assets/twitter.png"
import AWS from 'aws-sdk'


import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  WorkplaceShareButton,
  FacebookMessengerShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  LinkedinIcon,
} from "react-share";

interface Props {
  single?: boolean;
  export?: boolean;
  frames?: boolean;
  submitted?: boolean;
  rooms: Room[];
  userPaintings: SavedPainting[];
  loadingMore: boolean;
  loadMore: () => void;
  searchTerm: string;
}

interface Room {
  paintings: SavedPainting[];
}

interface State {
  paintingUrl: string
}

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

// create S3 instance
const s3 = new AWS.S3({ region: 'eu-west-2' })

export default class Wall extends React.Component<Props, State> {
  private paintingRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.paintingRef = React.createRef();
    if (props.single){

    }
  }

  wallRef = React.createRef<HTMLDivElement>();

  state: State = {
    paintingUrl: "https://www.mondrian.fun/mondrian-wide.png",
  };

copyToClipboard(text: string) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}




  render() {
    console.log("rooms", this.props.rooms)

    return (
      <div
        style={{
          WebkitOverflowScrolling: "touch",
        }}
      >
        <InfiniteScroll
          onScroll={(position: any, previousPosition: any) => {
            if (
              position > previousPosition &&
              !this.props.loadingMore &&
              this.wallRef &&
              this.wallRef.current
            ) {
              if (position > this.wallRef.current.clientWidth - toPx("400vw")) {
                this.props.loadMore();
              }
            }
          }}
          horizontal
        >
          <div id="scroller" ref={this.wallRef} style={{ display: "table" }}>
            {!this.props.single && (
              <div className="gallery__title">
                {this.props.frames ? "Hall of Fun" : "Gallery"}
              </div>
            )}
            {/* {!this.props.frames && !this.props.export && (
              <a href="/hall-of-fun" className="hall-of-fame-link">
                Hall of Fun
              </a>
            )} */}
            {/* {!this.props.export && <a
              target="_blank"
              href="https://twitter.com/mondrianfun"
              className="twitter-link"
            >
              Twitter Gallery
            </a>} */}
            <div
            style={{
              position: "absolute",
              margin: 8,
              top: 0,
              right: 0,
              fontSize: 12,
             
              zIndex: 999999,
            }}
            className="links"
          >
            <span>
            <span style={{   opacity: 0.3}}>Site designed and built by{" "}</span>
            <a
              style={{ color: "black", textDecoration: "underline",  opacity: 0.3, }}
              href="https://bengrav.es/"
              target="_blank"
            >
              Ben Graves
            </a>
            <a
            className="twitter-link"
              style={{ color: "#1DA1F2" }}
              href="https://twitter.com/mondrianfun"
              target="_blank"
            >
              @mondrianfun <img style={{height: 20}} src={TwitterLogo} />
            </a>
            </span>
          </div>
            {this.props.rooms.length === 0 ||
            this.props.rooms[0].paintings.length === 0 ? (
              <div
                style={{
                  marginTop: "45vh",
                  marginLeft: "20vw",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={LoadingGif} style={{ height: 25, marginRight: 9 }} />
                Loading...
              </div>
            ) : (
              this.props.userPaintings.length > 0 || this.props.submitted &&  (
               
                  <div className="thank-you" style={{ width: 320, display: "inline-block" }}>
                    <div style={{ position: "absolute", top: "45%" }}>
                      <div>Thanks for your submission!</div>
                      <div style={{ fontSize: "0.8em", marginTop:8, marginBottom:16 }}>You can see it in the gallery here -></div>
                      <button onClick={ () => window.location.href = this.props.userPaintings.length ? `/painting/${this.props.userPaintings[0].details.id}` : `/painting/${this.props.rooms[0].paintings[0].details.id}` } style={{backgroundColor: "transparent", border: "2px solid black"}}>Download / Share your painting</button>
                      {/* <a
              target="_blank"
              href="https://twitter.com/mondrianfun"
              className="twitter-link-inline"
            >
              We share our favourite submissions<br /> on twitter @mondrianfun!
            </a> */}
                    </div>
                  </div>
                
              )
            )}

            {this.props.userPaintings.map((painting, index) => (
              <div
                className="gallery__hanging"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!this.props.single) {
                    window.location.href = `/painting/${
                      (this.props.rooms[0].paintings[0].details.index || 0) +
                      this.props.userPaintings.length -
                      index
                    }`;
                  }
                }}
              >
                <Canvas
                  gallery={true}
                  painting={painting.painting}
                  frame={this.props.frames}
                />
                <div className="hanging__label">
                  <div className="handing__artist">
                    {painting.details.artist}
                  </div>
                  <div className="handing__title">{painting.details.title}</div>
                  <div className="handing__year">
                    {!this.props.searchTerm.length && <><span>
                      #
                      {(this.props.rooms[0].paintings[0].details.index || 0) +
                        this.props.userPaintings.length -
                        index +
                        1}
                    </span>
                    - </>}{painting.details.year}
                  </div>
                </div>
              </div>
            ))}
            {this.props.rooms.map((room) =>
              room.paintings.map((painting) =>
                painting !== null ? (
                  <div
                    className="gallery__hanging"
                    style={
                      !this.props.single
                        ? {
                            cursor: "pointer",
                          }
                        : {}
                    }
                    onClick={() => {
                      if (!this.props.single) {
                        window.location.href = `/painting/${painting.details.id}`;
                        // console.log(painting.details.id);
                      }
                    }}
                  >
                    <Canvas
                      gallery={true}
                      frame={this.props.frames}
                      painting={painting.painting}
                      paintingRef={this.paintingRef}
                    />
                    <div className="hanging__label">
                      <div className="handing__artist">
                        {painting.details.artist}
                      </div>
                      <div className="handing__title">
                        {painting.details.title}
                      </div>
                      <div className="handing__year">
                        {!this.props.searchTerm.length && <><span>#{painting.details.index}</span> -{" "}</>}
                        {painting.details.year}
                      </div>
                     
                      <a className="handing__twitter" href={"https://twitter.com/"+painting.details.twitter} target="_blank">
                        {painting.details.twitter}
                      </a>
                

                      <div className="handing__link">
                        {/* {this.props.single && !this.props.export && "Share: "}
                        {this.props.single && !this.props.export && (
                          <a
                            href={
                              painting.details.id
                                ? window.location.origin +
                                  "/painting/" +
                                  painting.details.id
                                : window.location.href
                            }
                          >
                            {painting.details.id
                              ? window.location.origin +
                                "/painting/" +
                                painting.details.id
                              : window.location.href}
                          </a>
                        )} */}
                        {!this.props.export && this.props.single && <button style={{backgroundColor: "transparent", border: "2px solid black"}} onClick={() => {
                          if(this.paintingRef.current){
                            console.log(`#${painting.details.index} ${painting.details.artist} - ${painting.details.title}\n${window.location.href}`);
                            this.copyToClipboard(`#${painting.details.index} ${painting.details.artist} - ${painting.details.title}\n${window.location.href}`)
                            toPng(this.paintingRef.current, { cacheBust: true, pixelRatio: 8 })
                              .then((dataUrl) => {
                                const link = document.createElement('a')
                                link.download = `${painting.details.artist}-${painting.details.title}.png`
                                link.href = dataUrl
                                link.click()
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                            }
                          }
                        }>
                        Download PNG
                        </button>}
                       
                        {/* {!this.props.export && this.props.single &&<div style={{margin: 8}}>
                          <FacebookShareButton url={window.location.href} quote={`${painting.details.artist} - ${painting.details.title}`}>
                            <FacebookIcon size={32} />
                          </FacebookShareButton>
                          <TwitterShareButton url={window.location.href} title={`@mondrianfun ${painting.details.artist} - ${painting.details.title}`}>
                            <TwitterIcon size={32} />
                          </TwitterShareButton>
                          <WhatsappShareButton url={window.location.href} title={`${painting.details.artist} - ${painting.details.title}`}>
                            <WhatsappIcon size={32} />
                          </WhatsappShareButton>
                          <RedditShareButton url={window.location.href} title={`${painting.details.artist} - ${painting.details.title}`}>
                            <RedditIcon size={32} />
                          </RedditShareButton>
                          <LinkedinShareButton url={window.location.href} title={`${painting.details.artist} - ${painting.details.title}`}>
                            <LinkedinIcon size={32} />
                          </LinkedinShareButton>
                        </div>} */}
                      </div>
                    </div>
                  </div>
                ) : null
              )
            )}
            {!this.props.single && (
              <div className="gallery__continue--wall">
                {"Exhibition continues this way ->"}
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}
